import React from 'react';
import Career from './Career';
import CareerHeaderDash from './CareerHeaderDash';

const CareerDashboard = () => {
  return (
    <div>
      <CareerHeaderDash />
      <Career />
    </div>
  );
};

export default CareerDashboard;
